$(function() {
    init();
});

function init() {
    window.addEventListener('scroll', function() {
        var distanceY = document.documentElement.scrollTop,
            shrinkOn = 160,
            nav = document.getElementById('top-nav');
        if (distanceY > shrinkOn) {
            nav.classList.add('navbar-fixed-top--compact');
        } else {
            nav.classList.remove('navbar-fixed-top--compact');
        }
    });

    $("#top-nav ul li a[href^='#']").on('click', function() {
        var target = this.hash;

        // prevent default anchor click behavior
        e.preventDefault();

        return $('html, body').animate({
            scrollTop: $(this.hash).offset().top
        }, 500, function() {
            return window.history.pushState(null, null, target);
        });
    });

    $(window).on('activate.bs.scrollspy', function (e) {
        history.replaceState({}, "", $("a[href^='#']", e.target).attr("href"));
    });

    document.getElementById('content').addEventListener('click', function() {
        document.getElementById('navbar').classList.remove('in');
    });
}
